<template>
  <div class="container">
    <div class="header">
      <span class="title">{{ title }}</span>
      <i class="rs-icon-close" @click="jumpBack"></i>
    </div>
    <render-form :list="renderList" :formData.sync="formValues" :rules="rules" ref="ruleForm" space="20" :show-message="false"></render-form>
    <div class="edit-form button-box" style="text-align: right">
      <rs-button size="small" @click="jumpBack">取消</rs-button>
      <rs-button size="small" @click="saveConfig" type="primary" :loading="saveLoading">保存</rs-button>
    </div>
  </div>
</template>
<script>
import headerTitle from '@/components/header'
import Bus from '@/utils/eventBus'
import Api from '@/api/imgSetter.js'

export default {
  components: { headerTitle },
  data() {
    return {
      title: '',
      list: ['会员微信小程序', '商户微信小程序', '微信公众号', '企业微信','钉钉'],
      formValues: {},
      rules: {
        AppId: [
          { required: true, message: 'AppId必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        AppSecret: [
          { required: true, message: 'AppSecret必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        AppToken: [
          { required: true, message: 'AppToken必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        AesKey: [
          { required: true, message: 'AesKey必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        AppSecret: [
          { required: true, message: 'AppSecret必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        CorpId: [
          { required: true, message: 'CorpId必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        Secret: [
          { required: true, message: 'Secret必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        AgentId: [
          { required: true, message: 'AgentId必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        RootDeptId: [
          { required: true, message: 'RootDeptId必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ],
        AppKey: [
          { required: true, message: 'AppKey必填', trigger: 'change' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' }
        ]
      },
      saveLoading: false,
      type: null, // configGroup值范围:1.登录页 2.首页配置 3.系统配置 4.会员微信小程序 5.商户微信小程序 6.微信公众号 7.企业微信 8.钉钉
      typeIndex: null
    }
  },
  mounted() {},
  methods: {
    //保存
    saveConfig() {
      this.$refs['ruleForm'].$refs.renderForm.validate((valid) => {
        if (valid) {
          let params = {
            configGroup: this.type,
            config: { ...this.formValues }
          }
          console.log('params', params)
          Api.saveConfig(params).then((res) => {
            this.jumpBack()
            console.log('res:', res)
          })
        }
      })
    },
    //返回
    jumpBack() {
      this.$router.push({ name: 'initialSetting', params: { from: 'third' } })
    },
    //获取详情
    getDetail() {
      let params = { configGroup: this.type }
      Api.findConfigByConfigGroup(params).then((res) => {
        if (res.data.code == 200) {
          this.formValues = res.data.data
        }
      })
    },
    renderList() {
      if (this.type == 4 || this.type == 5 || this.type == 6) {
        this.renderList = [
          {
            span: 18,
            type: 'input',
            label: 'AppId',
            key: 'AppId'
          },
          {
            span: 18,
            type: 'input',
            label: 'AppSecret',
            key: 'AppSecret'
          },
          {
            span: 18,
            type: 'input',
            label: 'AppToken',
            key: 'AppToken'
          },
          {
            span: 18,
            type: 'input',
            label: 'AesKey',
            key: 'AesKey'
          }
        ]
      }
      if (this.type == 7 || this.type == 8) {
        this.renderList = [
          {
            span: 18,
            type: 'input',
            label: 'CorpId',
            key: 'CorpId'
          },
          {
            span: 18,
            type: 'input',
            label: 'Secret',
            key: 'Secret'
          },
          {
            span: 18,
            type: 'input',
            label: 'AgentId',
            key: 'AgentId'
          }
        ]
        if (this.type == 7) {
          this.renderList.push({span: 18,type: 'input',label: 'RootDeptId',key: 'RootDeptId'})
        }
        if (this.type == 8) {
          this.renderList.push({span: 18,type: 'input',label: 'AppKey',key: 'AppKey'})
        }
      }
    }
  },
  created() {
    this.typeIndex = this.$route.query.typeIndex
    this.type = this.$route.query.type
    this.title = this.list[this.typeIndex]
    this.renderList()
    this.getDetail()
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 44px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 30px;

  .title {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 22px;
  }

  .rs-icon-close {
    font-size: 20px;
    color: #999999;
    cursor: pointer;
  }
}
</style>
