<template>
    <div class="header">
        <span class="title">{{title}}</span>
        <slot name="center"></slot>
        <i class="rs-icon-close" @click="handleClose"></i>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            }
        },
        data() {
            return {

            }
        },
        methods: {
            handleClose() {
                this.$router.back();
            },
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {},
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {}
    }
</script>
<style lang="less" scoped>
    .header {
        height: 44px;
        border-bottom: 1px solid #DDDDDD;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 30px;

        .title {
            font-size: 16px;
            color: #000000;
            letter-spacing: 0;
            line-height: 22px;
        }

        .rs-icon-close {
            font-size: 20px;
            color: #999999;
            cursor: pointer;
        }
    }
</style>