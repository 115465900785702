import Http from '../utils/axios'
//const upload = '/api/user'
const upload = '/api/user'
const user = `/api/user`
const perfix = `/api`

export default {
    /*
    ***  上传附件
    */
    uploadUrl() {
        return `${upload}/v1/e/upload/attach`;
    },
    findAllConfig(params){
        return Http.get(`${user}/v1/e/config/api/findAllConfig`,params,true)
    },
    save(params){
        return Http.post(`${user}/v1/e/config/api/saveOrUpdateBatch`,params,true)
    },
    getMerchantImg(){
        return Http.get(`${user}/v1/e/qrCode/createQRCode`)
    },
    saveConfig(params){
        return Http.post(`${user}/v1/e/config/api/saveConfig`,params,true)
    },
    saveAccountConfig(params){
        return Http.post(`${perfix}/admin/e/accountConfig/saveAccountConfig`,params,true)
    },
    uploadCert(){
        return `${perfix}/admin/e/accountConfig/uploadCert`
    },
    findPage(params){
        return Http.post(`${perfix}/admin/e/accountConfig/findPage`,params,true)
    },
    findDetailById(params){
        return Http.get(`${perfix}/admin/e/accountConfig/findDetailById`,params,true)
    },
    findConfigByConfigGroup(params){
        return Http.get(`${perfix}/user/v1/e/config/api/findConfigByConfigGroup`,params,true)
    },
    //获取所有主体公司
    queryCompanyListByType(params){
        return Http.post(`${perfix}/investment/e/common/selectCompanyListByType`,params)
    },

}

