var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "header" }, [
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("i", { staticClass: "rs-icon-close", on: { click: _vm.jumpBack } }),
      ]),
      _c("render-form", {
        ref: "ruleForm",
        attrs: {
          list: _vm.renderList,
          formData: _vm.formValues,
          rules: _vm.rules,
          space: "20",
          "show-message": false,
        },
        on: {
          "update:formData": function ($event) {
            _vm.formValues = $event
          },
          "update:form-data": function ($event) {
            _vm.formValues = $event
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            { attrs: { size: "small" }, on: { click: _vm.jumpBack } },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.saveLoading,
              },
              on: { click: _vm.saveConfig },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }